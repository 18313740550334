import React                                         from 'react';
import { Global, css }                               from '@emotion/react';
import { useStaticQuery, graphql }                   from 'gatsby';
import { Box, useBreakpointValue, useTheme }         from '@chakra-ui/react';
import styled                                        from '@emotion/styled';
import { readableColor }                             from 'polished';

import BaseLayout    from '@interness/web-core/src/components/Layout/Base';
import Mounted       from '@interness/web-core/src/components/structure/Mounted/Mounted';
import CookieConsent from '@interness/web-core/src/components/modules/CookieConsent/CookieConsent';

import Header      from '../Header/Header';
import Sidebar     from '../Sidebar';
import Footer      from '../Footer/Footer';
import PrimaryMenu from '../PrimaryMenu';

const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1600px;
  background-color: ${props => props.theme.base_color};
  margin: auto;
`;

const DefaultLayout = ({ children, pageContext }) => {
  const { theme } = useStaticQuery(query);
  const chakraTheme = useTheme();
  const headerPosition = useBreakpointValue({
    base: 'relative',
    md: 'relative',
    lg: 'sticky',
    xl: 'sticky',
    '2xl': 'sticky',
  }, {
    fallback: 'lg'
  })
  const displayMobileMenu = useBreakpointValue(
    {
      base: 'block',
      md: 'block',
      lg: 'none',
      xl: 'none',
      '2xl': 'none',
    },
    {
      fallback: 'md',
    },
  )
  const globalStyles = css`
    body {
      background-color: ${chakraTheme.initialColorMode === 'dark' ? chakraTheme.colors.base['400'] : chakraTheme.colors.base['600']};
    }

    body, h1, h2, h3, h4, h5, h6, p, b {
      color: ${readableColor(chakraTheme.colors.base['500'])};
    }

    h1, h2, h3, h4, h5, h6 {
      font-weight: 300 !important;
    }

    h1 {
      font-size: 1.9rem !important;
    }

    a {
      color: ${theme.brand_color};
    }
  `;

  return (
    <BaseLayout pageContext={pageContext}>
      <PageWrapper>
        <Global styles={globalStyles}/>
        <CookieConsent/>
        <Box position="relative" minH="100vh">
          <Mounted>
            <Box position={headerPosition} top="0" zIndex="99">
              <Header/>
            </Box>
          </Mounted>
          <Box paddingBottom={['150px', '150px', '150px', '150px', '300px']}>
            <main>{children}</main>
            <Sidebar footerDisplay={displayMobileMenu}/>
          </Box>
          <Box position={['relative', 'relative', 'relative', 'relative', 'absolute']}
               bottom={['100px', '100px', '100px', '100px', '0']} width="100%">
            <Footer/>
          </Box>
          <Mounted>
            <Box position={'fixed'} bottom="0" right="0" left="0" bg="base.500" display={displayMobileMenu} w="100%"
                 h="100px"
                 borderTop={`3px solid ${theme.brand_color}`} zIndex="99">
              <PrimaryMenu/>
            </Box>
          </Mounted>
        </Box>
      </PageWrapper>
    </BaseLayout>
  )
};

const query = graphql`
    query {
        theme {
            ...CoreTheme
        }
    }
`;

export default DefaultLayout;
